import { BaSeSideMenu } from '@base/react';
import * as Sebrae from '@sebrae/react-core';
import * as React from 'react';
import { Role } from '../../models/role';
import { SideMenu } from './footer-styled';

export const LayoutAside = () => {
  const { isAuthenticated, hasRoles } = Sebrae.useAuth();
  const { navigateTo, isInRoute } = Sebrae.useRouter();
  const linkSideBar = [
    {
      label: 'Relatórios',
      active: isInRoute('/relatorio/cliente'),
      subItens: [
        {
          label: 'Clientes - Fluxo Relacionamento',
          active: isInRoute('/relatorio/cliente'),
          callback() {
            navigateTo('/relatorio/cliente');
          },
        },
        {
          label: 'Clientes - Cadastro Completo',
          active: isInRoute('/relatorio/cliente-cadastro-completo'),
          callback() {
            navigateTo('/relatorio/cliente-cadastro-completo');
          },
        },
        {
          label: 'Operações de Crédito',
          active: isInRoute('/relatorio/operacao-credito'),
          callback() {
            navigateTo('/relatorio/operacao-credito');
          },
        },
        {
          label: 'Monitoramento Pré e Pós Crédito - Sintético',
          active: isInRoute('/relatorio/manutencao-relacionamento-sintetico'),
          callback() {
            navigateTo('/relatorio/manutencao-relacionamento-sintetico');
          },
        },
        {
          label: 'Monitoramento Pré e Pós Crédito - Analítico',
          active: isInRoute('/relatorio/manutencao-relacionamento-analitico'),
          callback() {
            navigateTo('/relatorio/manutencao-relacionamento-analitico');
          },
        },
        {
          label: 'Monitoramento de Rotinas Automáticas',
          active: isInRoute('/relatorio/automatic-routines-monitoring'),
          callback() {
            navigateTo('/relatorio/automatic-routines-monitoring');
          },
        },
        {
          label: 'Orientação para Crédito - consulta IFs',
          active: isInRoute('/relatorio/exigibilidade-mpo'),
          callback() {
            navigateTo('/relatorio/exigibilidade-mpo');
          },
        },
        {
          label: 'Soluções Parametrizadas',
          active: isInRoute('/relatorio/relatorio-solucoes-parametrizadas'),
          callback() {
            navigateTo('/relatorio/relatorio-solucoes-parametrizadas');
          },
        },
      ],
    },
  ];

  const configSideBarItens = [
    {
      label: 'Configuração',
      active: isInRoute('/relatorio/cliente'),
      subItens: [
        {
          label: 'Configurar perfil de acesso',
          active: isInRoute('/configurar-perfil-acesso'),
          callback() {
            navigateTo('/configurar-perfil-acesso');
          },
        },
        {
          label: 'Informar Projeto/ação',
          active: isInRoute('/informar-projeto-acao'),
          callback() {
            navigateTo('/informar-projeto-acao');
          },
        },
        // {
        //   label: 'Parametrizar UF para e-mailo',
        //   active: isInRoute('/parametrizar-uf-email'),
        //   callback() {
        //     navigateTo('/parametrizar-uf-email');
        //   },
        // },
        {
          label: 'Parametrizar soluções',
          active: isInRoute('/parametrizar-solucoes'),
          callback() {
            navigateTo('/parametrizar-solucoes');
          },
        },
        {
          label: 'Agrupar soluções',
          active: isInRoute('/agrupar-solucoes'),
          callback() {
            navigateTo('/agrupar-solucoes');
          },
        },
      ],
    },
  ];

  const importFileSideBarItens = [
    {
      label: 'Importação de Arquivo',
      active: isInRoute('/importar-clientes-fampe'),
      subItens: [
        {
          label: 'Importar Cliente FAMPE',
          active: isInRoute('/importar-clientes-fampe'),
          callback() {
            navigateTo('/importar-clientes-fampe');
          },
        },
      ],
    },
  ];

  return (
    <SideMenu>
      <BaSeSideMenu itens={linkSideBar} themeMode="light" />
      {isAuthenticated && hasRoles([Role.GestorNacional]) && (
        <>
          <BaSeSideMenu itens={configSideBarItens} themeMode="light" />
          <BaSeSideMenu itens={importFileSideBarItens} themeMode="light" />
        </>
      )}
    </SideMenu>
  );
};
